<template>
    <div class="d-flex align-items-center over-hover position-relative align-self-end col-auto">
        <div class="d-flex flex-column">
            <PlatonLink
                v-if="isAdmin && isMyProject(item) && hasPermFormBtn"
                :link="`/forms/table_buttons/${item.id}?_target=modal&table_name=${item.tableName}&table_level=${item.tableLevel}`"
                class="small over-target align-self-end"
            >
                <i class="fa fa-cog"></i>
            </PlatonLink>

            <div>
                <Btn
                    v-if="bType === 'refresh_table'"
                    :class="`${cssClass}`"
                    :style="cssStyle"
                    :icon="iconClass"
                    @click.native="table.reload()"
                >
                    {{ bTitle }}
                </Btn>

                <Btn
                    v-if="bType === 'columns_filter'"
                    :class="`${cssClass}`"
                    :style="cssStyle"
                    :icon="iconClass"
                    @click.native="table.tableColumnFilter()"
                    class="d-flex align-items-center"
                >
                    <i class="mdi mdi-table-cog mr-1" style="margin-top: 1.2px"></i>
                    {{ $l("platon.columns.settings", " Устунларни созлаш") }}
                </Btn>

                <ActionBtn
                    v-else-if="isAction"
                    :classes="`${cssClass}`"
                    :style="cssStyle"
                    :icon="iconClass"
                    :js="item.js"
                    :js-context="table"
                >
                    {{ bTitle }}
                </ActionBtn>

                <PlatonLink v-else :link="bLink">
                    <Btn :class="`${cssClass}`" :style="cssStyle" :icon="iconClass">{{ bTitle }}</Btn>
                </PlatonLink>
            </div>
        </div>
    </div>
</template>
<script>
import Btn from "@Platon/components/extended/Btn.vue"
import ActionBtn from "@Platon/components/extended/ActionBtn.vue"
import PlatonLink from "@Platon/components/extended/PlatonLink.vue"
import PermissionMixin from "@Platon/mixins/table/PermissionMixin"
import { EXPORT_RESOURCE_URL, PlatonTableNames } from "@Platon/const"
import { addParamsFromRoute } from "@Platon/core/http/HttpHelpers"
import NavigationMixin from "@Platon/mixins/NavigationMixin"

export default {
    name: "TableButton",

    mixins: [PermissionMixin, NavigationMixin],

    components: { ActionBtn, PlatonLink, Btn },

    props: {
        /** @type {TableButton} */
        item: {},

        table: {
            type: Object,
            required: true
        }
    },

    computed: {
        cssClass() {
            return this.item.cssClass
        },

        cssStyle() {
            return this.item.cssStyle
        },

        iconClass() {
            return this.item.iconClass
        },

        bLink() {
            if (this.item.type === "export_resource") {
                return addParamsFromRoute(EXPORT_RESOURCE_URL(this.item.exportResourceName), this.table.localRoute)
            }

            return this.item.link
        },

        bTitle() {
            return this.item.title || "button"
        },

        bWidth() {
            return this.item.width || 2
        },

        bType() {
            return this.item.type
        },

        isAction() {
            return this.bType === "action"
        },

        hasPermFormBtn() {
            return !PlatonTableNames.includes(this.table.tableName)
        }
    }
}
</script>

<style></style>
